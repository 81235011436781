import { createRouter, createWebHistory } from 'vue-router'
import { checkUserToken } from '../utils/handleSession'

async function checkAuth(to, from, next) {
  const token = checkUserToken()
  if (!token) {
    next({ name: 'login' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '',
    redirect: 'login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/',
    redirect: 'login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/reset/:encoded',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: checkAuth,
    component: () => import('../views/Dashboard/Dashboard.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    beforeEnter: checkAuth,
    component: () => import('../views/Orders.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: checkAuth,
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/add-user',
    name: 'add-user',
    beforeEnter: checkAuth,
    component: () => import('../views/InviteUser')
  },
  {
    path: '/invite/:encoded',
    name: 'invite',
    component: () => import('../views/SetAccountPassword.vue')
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export { routes }

export default router
